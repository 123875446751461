import { render, staticRenderFns } from "./SendSms.vue?vue&type=template&id=51d71d86&scoped=true"
import script from "./SendSms.vue?vue&type=script&lang=js"
export * from "./SendSms.vue?vue&type=script&lang=js"
import style0 from "./SendSms.vue?vue&type=style&index=0&id=51d71d86&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51d71d86",
  null
  
)

export default component.exports