export const contryList = [
  {
    'prefixes': '93',   // 手机区号
    'iso': 'AF',
    'name': 'Afghanistan',
  },
  {
    'prefixes': '358',
    'iso': 'AX',
    'name': 'Åland Islands',
  },
  {
    'prefixes': '355',
    'iso': 'AL',
    'name': 'Albania',
  },
  {
    'prefixes': '213',
    'iso': 'DZ',
    'name': 'Algeria',
  },
  {
    'prefixes': '1684',
    'iso': 'AS',
    'name': 'American Samoa',
  },
  {
    'prefixes': '376',
    'iso': 'AD',
    'name': 'Andorra',
  },
  {
    'prefixes': '244',
    'iso': 'AO',
    'name': 'Angola',
  },
  {
    'prefixes': '1264',
    'iso': 'AI',
    'name': 'Anguilla',
  },
  {
    'prefixes': '672',
    'iso': 'AQ',
    'name': 'Antarctica',
  },
  {
    'prefixes': '1268',
    'iso': 'AG',
    'name': 'Antigua & Barbuda',
  },
  {
    'prefixes': '54',
    'iso': 'AR',
    'name': 'Argentina',
  },
  {
    'prefixes': '374',
    'iso': 'AM',
    'name': 'Armenia',
  },
  {
    'prefixes': '297',
    'iso': 'AW',
    'name': 'Aruba',
  },
  {
    'prefixes': '61',
    'iso': 'AU',
    'name': 'Australia',
  },
  {
    'prefixes': '43',
    'iso': 'AT',
    'name': 'Austria',
  },
  {
    'prefixes': '994',
    'iso': 'AZ',
    'name': 'Azerbaijan',
  },
  {
    'prefixes': '1242',
    'iso': 'BS',
    'name': 'Bahamas',
  },
  {
    'prefixes': '973',
    'iso': 'BH',
    'name': 'Bahrain',
  },
  {
    'prefixes': '880',
    'iso': 'BD',
    'name': 'Bangladesh',
  },
  {
    'prefixes': '1246',
    'iso': 'BB',
    'name': 'Barbados',
  },
  {
    'prefixes': '375',
    'iso': 'BY',
    'name': 'Belarus',
  },
  {
    'prefixes': '32',
    'iso': 'BE',
    'name': 'Belgium',
  },
  {
    'prefixes': '501',
    'iso': 'BZ',
    'name': 'Belize',
  },
  {
    'prefixes': '229',
    'iso': 'BJ',
    'name': 'Benin',
  },
  {
    'prefixes': '1441',
    'iso': 'BM',
    'name': 'Bermuda',
  },
  {
    'prefixes': '975',
    'iso': 'BT',
    'name': 'Bhutan',
  },
  {
    'prefixes': '591',
    'iso': 'BO',
    'name': 'Bolivia',
  },
  {
    'prefixes': '599',
    'iso': 'BQ',
    'name': 'Bonaire',
  },
  {
    'prefixes': '387',
    'iso': 'BA',
    'name': 'Bosnia & Herzegovina',
  },
  {
    'prefixes': '267',
    'iso': 'BW',
    'name': 'Botswana',
  },
  {
    'prefixes': '47',
    'iso': 'BV',
    'name': 'Bouvet Island',
  },
  {
    'prefixes': '55',
    'iso': 'BR',
    'name': 'Brazil',
  },
  {
    'prefixes': '246',
    'iso': 'IO',
    'name': 'British Indian Ocean Territory',
  },
  {
    'prefixes': '1284',
    'iso': 'VG',
    'name': 'British Virgin Islands',
  },
  {
    'prefixes': '673',
    'iso': 'BN',
    'name': 'Brunei',
  },
  {
    'prefixes': '359',
    'iso': 'BG',
    'name': 'Bulgaria',
  },
  {
    'prefixes': '226',
    'iso': 'BF',
    'name': 'Burkina Faso',
  },
  {
    'prefixes': '257',
    'iso': 'BI',
    'name': 'Burundi',
  },
  {
    'prefixes': '855',
    'iso': 'KH',
    'name': 'Cambodia',
  },
  {
    'prefixes': '237',
    'iso': 'CM',
    'name': 'Cameroon',
  },
  {
    'prefixes': '1',
    'iso': 'CA',
    'name': 'Canada',
  },
  {
    'prefixes': '238',
    'iso': 'CV',
    'name': 'Cape Verde',
  },
  {
    'prefixes': '1345',
    'iso': 'KY',
    'name': 'Cayman Islands',
  },
  {
    'prefixes': '236',
    'iso': 'CF',
    'name': 'Central African Republic',
  },
  {
    'prefixes': '235',
    'iso': 'TD',
    'name': 'Chad',
  },
  {
    'prefixes': '56',
    'iso': 'CL',
    'name': 'Chile',
  },
  {
    'prefixes': '86',
    'iso': 'CN',
    'name': 'China',
  },
  {
    'prefixes': '61',
    'iso': 'CX',
    'name': 'Christmas Island',
  },
  {
    'prefixes': '61',
    'iso': 'CC',
    'name': 'Cocos (Keeling) Islands',
  },
  {
    'prefixes': '57',
    'iso': 'CO',
    'name': 'Colombia',
  },
  {
    'prefixes': '269',
    'iso': 'KM',
    'name': 'Comoros',
  },
  {
    'prefixes': '242',
    'iso': 'CG',
    'name': 'Congo - Brazzaville',
  },
  {
    'prefixes': '243',
    'iso': 'CD',
    'name': 'Congo - Kinshasa',
  },
  {
    'prefixes': '682',
    'iso': 'CK',
    'name': 'Cook Islands',
  },
  {
    'prefixes': '506',
    'iso': 'CR',
    'name': 'Costa Rica',
  },
  {
    'prefixes': '225',
    'iso': 'CI',
    'name': 'Côte d’Ivoire',
  },
  {
    'prefixes': '385',
    'iso': 'HR',
    'name': 'Croatia',
  },
  {
    'prefixes': '53',
    'iso': 'CU',
    'name': 'Cuba',
  },
  {
    'prefixes': '599',
    'iso': 'CW',
    'name': 'Curaçao',
  },
  {
    'prefixes': '357',
    'iso': 'CY',
    'name': 'Cyprus',
  },
  {
    'prefixes': '420',
    'iso': 'CZ',
    'name': 'Czechia',
  },
  {
    'prefixes': '45',
    'iso': 'DK',
    'name': 'Denmark',
  },
  {
    'prefixes': '253',
    'iso': 'DJ',
    'name': 'Djibouti',
  },
  {
    'prefixes': '1767',
    'iso': 'DM',
    'name': 'Dominica',
  },
  {
    'prefixes': '18',
    'iso': 'DO',
    'name': 'Dominican Republic',
  },
  {
    'prefixes': '593',
    'iso': 'EC',
    'name': 'Ecuador',
  },
  {
    'prefixes': '20',
    'iso': 'EG',
    'name': 'Egypt',
  },
  {
    'prefixes': '503',
    'iso': 'SV',
    'name': 'El Salvador',
  },
  {
    'prefixes': '240',
    'iso': 'GQ',
    'name': 'Equatorial Guinea',
  },
  {
    'prefixes': '291',
    'iso': 'ER',
    'name': 'Eritrea',
  },
  {
    'prefixes': '372',
    'iso': 'EE',
    'name': 'Estonia',
  },
  {
    'prefixes': '268',
    'iso': 'SZ',
    'name': 'Eswatini',
  },
  {
    'prefixes': '251',
    'iso': 'ET',
    'name': 'Ethiopia',
  },
  {
    'prefixes': '500',
    'iso': 'FK',
    'name': 'Falkland Islands',
  },
  {
    'prefixes': '298',
    'iso': 'FO',
    'name': 'Faroe Islands',
  },
  {
    'prefixes': '679',
    'iso': 'FJ',
    'name': 'Fiji',
  },
  {
    'prefixes': '358',
    'iso': 'FI',
    'name': 'Finland',
  },
  {
    'prefixes': '33',
    'iso': 'FR',
    'name': 'France',
  },
  {
    'prefixes': '594',
    'iso': 'GF',
    'name': 'French Guiana',
  },
  {
    'prefixes': '689',
    'iso': 'PF',
    'name': 'French Polynesia',
  },
  {
    'prefixes': '262',
    'iso': 'TF',
    'name': 'French Southern Territories',
  },
  {
    'prefixes': '241',
    'iso': 'GA',
    'name': 'Gabon',
  },
  {
    'prefixes': '220',
    'iso': 'GM',
    'name': 'Gambia',
  },
  {
    'prefixes': '995',
    'iso': 'GE',
    'name': 'Georgia',
  },
  {
    'prefixes': '49',
    'iso': 'DE',
    'name': 'Germany',
  },
  {
    'prefixes': '233',
    'iso': 'GH',
    'name': 'Ghana',
  },
  {
    'prefixes': '350',
    'iso': 'GI',
    'name': 'Gibraltar',
  },
  {
    'prefixes': '30',
    'iso': 'GR',
    'name': 'Greece',
  },
  {
    'prefixes': '299',
    'iso': 'GL',
    'name': 'Greenland',
  },
  {
    'prefixes': '1473',
    'iso': 'GD',
    'name': 'Grenada',
  },
  {
    'prefixes': '590',
    'iso': 'GP',
    'name': 'Guadeloupe',
  },
  {
    'prefixes': '1671',
    'iso': 'GU',
    'name': 'Guam',
  },
  {
    'prefixes': '502',
    'iso': 'GT',
    'name': 'Guatemala',
  },
  {
    'prefixes': '44',
    'iso': 'GG',
    'name': 'Guernsey',
  },
  {
    'prefixes': '224',
    'iso': 'GN',
    'name': 'Guinea',
  },
  {
    'prefixes': '245',
    'iso': 'GW',
    'name': 'Guinea-Bissau',
  },
  {
    'prefixes': '592',
    'iso': 'GY',
    'name': 'Guyana',
  },
  {
    'prefixes': '509',
    'iso': 'HT',
    'name': 'Haiti',
  },
  {
    'prefixes': '672',
    'iso': 'HM',
    'name': 'Heard & McDonald Islands',
  },
  {
    'prefixes': '504',
    'iso': 'HN',
    'name': 'Honduras',
  },
  {
    'prefixes': '852',
    'iso': 'HK',
    'name': 'Hong Kong SAR China',
  },
  {
    'prefixes': '36',
    'iso': 'HU',
    'name': 'Hungary',
  },
  {
    'prefixes': '354',
    'iso': 'IS',
    'name': 'Iceland',
  },
  {
    'prefixes': '91',
    'iso': 'IN',
    'name': 'India',
  },
  {
    'prefixes': '62',
    'iso': 'ID',
    'name': 'Indonesia',
  },
  {
    'prefixes': '98',
    'iso': 'IR',
    'name': 'Iran',
  },
  {
    'prefixes': '964',
    'iso': 'IQ',
    'name': 'Iraq',
  },
  {
    'prefixes': '353',
    'iso': 'IE',
    'name': 'Ireland',
  },
  {
    'prefixes': '44',
    'iso': 'IM',
    'name': 'Isle of Man',
  },
  {
    'prefixes': '972',
    'iso': 'IL',
    'name': 'Israel',
  },
  {
    'prefixes': '39',
    'iso': 'IT',
    'name': 'Italy',
  },
  {
    'prefixes': '1876',
    'iso': 'JM',
    'name': 'Jamaica',
  },
  {
    'prefixes': '81',
    'iso': 'JP',
    'name': 'Japan',
  },
  {
    'prefixes': '44',
    'iso': 'JE',
    'name': 'Jersey',
  },
  {
    'prefixes': '962',
    'iso': 'JO',
    'name': 'Jordan',
  },
  {
    'prefixes': '7',
    'iso': 'KZ',
    'name': 'Kazakhstan',
  },
  {
    'prefixes': '254',
    'iso': 'KE',
    'name': 'Kenya',
  },
  {
    'prefixes': '686',
    'iso': 'KI',
    'name': 'Kiribati',
  },
  {
    'prefixes': '383',
    'iso': 'XK',
    'name': 'Kosovo',
  },
  {
    'prefixes': '965',
    'iso': 'KW',
    'name': 'Kuwait',
  },
  {
    'prefixes': '996',
    'iso': 'KG',
    'name': 'Kyrgyzstan',
  },
  {
    'prefixes': '856',
    'iso': 'LA',
    'name': 'Laos',
  },
  {
    'prefixes': '371',
    'iso': 'LV',
    'name': 'Latvia',
  },
  {
    'prefixes': '961',
    'iso': 'LB',
    'name': 'Lebanon',
  },
  {
    'prefixes': '266',
    'iso': 'LS',
    'name': 'Lesotho',
  },
  {
    'prefixes': '231',
    'iso': 'LR',
    'name': 'Liberia',
  },
  {
    'prefixes': '218',
    'iso': 'LY',
    'name': 'Libya',
  },
  {
    'prefixes': '423',
    'iso': 'LI',
    'name': 'Liechtenstein',
  },
  {
    'prefixes': '370',
    'iso': 'LT',
    'name': 'Lithuania',
  },
  {
    'prefixes': '352',
    'iso': 'LU',
    'name': 'Luxembourg',
  },
  {
    'prefixes': '853',
    'iso': 'MO',
    'name': 'Macao SAR China',
  },
  {
    'prefixes': '261',
    'iso': 'MG',
    'name': 'Madagascar',
  },
  {
    'prefixes': '265',
    'iso': 'MW',
    'name': 'Malawi',
  },
  {
    'prefixes': '60',
    'iso': 'MY',
    'name': 'Malaysia',
  },
  {
    'prefixes': '960',
    'iso': 'MV',
    'name': 'Maldives',
  },
  {
    'prefixes': '223',
    'iso': 'ML',
    'name': 'Mali',
  },
  {
    'prefixes': '356',
    'iso': 'MT',
    'name': 'Malta',
  },
  {
    'prefixes': '692',
    'iso': 'MH',
    'name': 'Marshall Islands',
  },
  {
    'prefixes': '596',
    'iso': 'MQ',
    'name': 'Martinique',
  },
  {
    'prefixes': '222',
    'iso': 'MR',
    'name': 'Mauritania',
  },
  {
    'prefixes': '230',
    'iso': 'MU',
    'name': 'Mauritius',
  },
  {
    'prefixes': '26',
    'iso': 'YT',
    'name': 'Mayotte',
  },
  {
    'prefixes': '52',
    'iso': 'MX',
    'name': 'Mexico',
  },
  {
    'prefixes': '691',
    'iso': 'FM',
    'name': 'Micronesia',
  },
  {
    'prefixes': '373',
    'iso': 'MD',
    'name': 'Moldova',
  },
  {
    'prefixes': '377',
    'iso': 'MC',
    'name': 'Monaco',
  },
  {
    'prefixes': '976',
    'iso': 'MN',
    'name': 'Mongolia',
  },
  {
    'prefixes': '382',
    'iso': 'ME',
    'name': 'Montenegro',
  },
  {
    'prefixes': '1664',
    'iso': 'MS',
    'name': 'Montserrat',
  },
  {
    'prefixes': '212',
    'iso': 'MA',
    'name': 'Morocco',
  },
  {
    'prefixes': '258',
    'iso': 'MZ',
    'name': 'Mozambique',
  },
  {
    'prefixes': '95',
    'iso': 'MM',
    'name': 'Myanmar (Burma)',
  },
  {
    'prefixes': '264',
    'iso': 'NA',
    'name': 'Namibia',
  },
  {
    'prefixes': '674',
    'iso': 'NR',
    'name': 'Nauru',
  },
  {
    'prefixes': '977',
    'iso': 'NP',
    'name': 'Nepal',
  },
  {
    'prefixes': '31',
    'iso': 'NL',
    'name': 'Netherlands',
  },
  {
    'prefixes': '687',
    'iso': 'NC',
    'name': 'New Caledonia',
  },
  {
    'prefixes': '64',
    'iso': 'NZ',
    'name': 'New Zealand',
  },
  {
    'prefixes': '505',
    'iso': 'NI',
    'name': 'Nicaragua',
  },
  {
    'prefixes': '227',
    'iso': 'NE',
    'name': 'Niger',
  },
  {
    'prefixes': '234',
    'iso': 'NG',
    'name': 'Nigeria',
  },
  {
    'prefixes': '683',
    'iso': 'NU',
    'name': 'Niue',
  },
  {
    'prefixes': '672',
    'iso': 'NF',
    'name': 'Norfolk Island',
  },
  {
    'prefixes': '850',
    'iso': 'KP',
    'name': 'North Korea',
  },
  {
    'prefixes': '389',
    'iso': 'MK',
    'name': 'North Macedonia',
  },
  {
    'prefixes': '1671',
    'iso': 'MP',
    'name': 'Northern Mariana Islands',
  },
  {
    'prefixes': '47',
    'iso': 'NO',
    'name': 'Norway',
  },
  {
    'prefixes': '968',
    'iso': 'OM',
    'name': 'Oman',
  },
  {
    'prefixes': '92',
    'iso': 'PK',
    'name': 'Pakistan',
  },
  {
    'prefixes': '680',
    'iso': 'PW',
    'name': 'Palau',
  },
  {
    'prefixes': '970',
    'iso': 'PS',
    'name': 'Palestinian Territories',
  },
  {
    'prefixes': '507',
    'iso': 'PA',
    'name': 'Panama',
  },
  {
    'prefixes': '675',
    'iso': 'PG',
    'name': 'Papua New Guinea',
  },
  {
    'prefixes': '595',
    'iso': 'PY',
    'name': 'Paraguay',
  },
  {
    'prefixes': '51',
    'iso': 'PE',
    'name': 'Peru',
  },
  {
    'prefixes': '63',
    'iso': 'PH',
    'name': 'Philippines',
  },
  {
    'prefixes': '870',
    'iso': 'PN',
    'name': 'Pitcairn Islands',
  },
  {
    'prefixes': '48',
    'iso': 'PL',
    'name': 'Poland',
  },
  {
    'prefixes': '351',
    'iso': 'PT',
    'name': 'Portugal',
  },
  {
    'prefixes': '1',
    'iso': 'PR',
    'name': 'Puerto Rico',
  },
  {
    'prefixes': '974',
    'iso': 'QA',
    'name': 'Qatar',
  },
  {
    'prefixes': '262',
    'iso': 'RE',
    'name': 'Réunion',
  },
  {
    'prefixes': '40',
    'iso': 'RO',
    'name': 'Romania',
  },
  {
    'prefixes': '7',
    'iso': 'RU',
    'name': 'Russia',
  },
  {
    'prefixes': '250',
    'iso': 'RW',
    'name': 'Rwanda',
  },
  {
    'prefixes': '685',
    'iso': 'WS',
    'name': 'Samoa',
  },
  {
    'prefixes': '378',
    'iso': 'SM',
    'name': 'San Marino',
  },
  {
    'prefixes': '239',
    'iso': 'ST',
    'name': 'São Tomé & Príncipe',
  },
  {
    'prefixes': '966',
    'iso': 'SA',
    'name': 'Saudi Arabia',
  },
  {
    'prefixes': '221',
    'iso': 'SN',
    'name': 'Senegal',
  },
  {
    'prefixes': '381',
    'iso': 'RS',
    'name': 'Serbia',
  },
  {
    'prefixes': '248',
    'iso': 'SC',
    'name': 'Seychelles',
  },
  {
    'prefixes': '232',
    'iso': 'SL',
    'name': 'Sierra Leone',
  },
  {
    'prefixes': '65',
    'iso': 'SG',
    'name': 'Singapore',
  },
  {
    'prefixes': '1',
    'iso': 'SX',
    'name': 'Sint Maarten',
  },
  {
    'prefixes': '421',
    'iso': 'SK',
    'name': 'Slovakia',
  },
  {
    'prefixes': '386',
    'iso': 'SI',
    'name': 'Slovenia',
  },
  {
    'prefixes': '677',
    'iso': 'SB',
    'name': 'Solomon Islands',
  },
  {
    'prefixes': '252',
    'iso': 'SO',
    'name': 'Somalia',
  },
  {
    'prefixes': '27',
    'iso': 'ZA',
    'name': 'South Africa',
  },
  {
    'prefixes': '500',
    'iso': 'GS',
    'name': 'South Georgia & South Sandwich Islands',
  },
  {
    'prefixes': '82',
    'iso': 'KR',
    'name': 'South Korea',
  },
  {
    'prefixes': '211',
    'iso': 'SS',
    'name': 'South Sudan',
  },
  {
    'prefixes': '34',
    'iso': 'ES',
    'name': 'Spain',
  },
  {
    'prefixes': '94',
    'iso': 'LK',
    'name': 'Sri Lanka',
  },
  {
    'prefixes': '290',
    'iso': 'SH',
    'name': 'St. Helena',
  },
  {
    'prefixes': '1869',
    'iso': 'KN',
    'name': 'St. Kitts & Nevis',
  },
  {
    'prefixes': '1758',
    'iso': 'LC',
    'name': 'St. Lucia',
  },
  {
    'prefixes': '721',
    'iso': 'MF',
    'name': 'St. Martin',
  },
  {
    'prefixes': '508',
    'iso': 'PM',
    'name': 'St. Pierre & Miquelon',
  },
  {
    'prefixes': '1784',
    'iso': 'VC',
    'name': 'St. Vincent & Grenadines',
  },
  {
    'prefixes': '249',
    'iso': 'SD',
    'name': 'Sudan',
  },
  {
    'prefixes': '597',
    'iso': 'SR',
    'name': 'Suriname',
  },
  {
    'prefixes': '47',
    'iso': 'SJ',
    'name': 'Svalbard & Jan Mayen',
  },
  {
    'prefixes': '46',
    'iso': 'SE',
    'name': 'Sweden',
  },
  {
    'prefixes': '41',
    'iso': 'CH',
    'name': 'Switzerland',
  },
  {
    'prefixes': '963',
    'iso': 'SY',
    'name': 'Syria',
  },
  {
    'prefixes': '886',
    'iso': 'TW',
    'name': 'Taiwan',
  },
  {
    'prefixes': '992',
    'iso': 'TJ',
    'name': 'Tajikistan',
  },
  {
    'prefixes': '255',
    'iso': 'TZ',
    'name': 'Tanzania',
  },
  {
    'prefixes': '66',
    'iso': 'TH',
    'name': 'Thailand',
  },
  {
    'prefixes': '670',
    'iso': 'TL',
    'name': 'Timor-Leste',
  },
  {
    'prefixes': '228',
    'iso': 'TG',
    'name': 'Togo',
  },
  {
    'prefixes': '690',
    'iso': 'TK',
    'name': 'Tokelau',
  },
  {
    'prefixes': '676',
    'iso': 'TO',
    'name': 'Tonga',
  },
  {
    'prefixes': '1868',
    'iso': 'TT',
    'name': 'Trinidad & Tobago',
  },
  {
    'prefixes': '216',
    'iso': 'TN',
    'name': 'Tunisia',
  },
  {
    'prefixes': '90',
    'iso': 'TR',
    'name': 'Turkey',
  },
  {
    'prefixes': '993',
    'iso': 'TM',
    'name': 'Turkmenistan',
  },
  {
    'prefixes': '1649',
    'iso': 'TC',
    'name': 'Turks & Caicos Islands',
  },
  {
    'prefixes': '688',
    'iso': 'TV',
    'name': 'Tuvalu',
  },
  {
    'prefixes': '1340',
    'iso': 'VI',
    'name': 'U.S. Virgin Islands',
  },
  {
    'prefixes': '256',
    'iso': 'UG',
    'name': 'Uganda',
  },
  {
    'prefixes': '380',
    'iso': 'UA',
    'name': 'Ukraine',
  },
  {
    'prefixes': '971',
    'iso': 'AE',
    'name': 'United Arab Emirates',
  },
  {
    'prefixes': '44',
    'iso': 'GB',
    'name': 'United Kingdom (UK)',
  },
  {
    'prefixes': '1',
    'iso': 'US',
    'name': 'United States (USA)',
  },
  {
    'prefixes': '598',
    'iso': 'UY',
    'name': 'Uruguay',
  },
  {
    'prefixes': '998',
    'iso': 'UZ',
    'name': 'Uzbekistan',
  },
  {
    'prefixes': '678',
    'iso': 'VU',
    'name': 'Vanuatu',
  },
  {
    'prefixes': '58',
    'iso': 'VE',
    'name': 'Venezuela',
  },
  {
    'prefixes': '84',
    'iso': 'VN',
    'name': 'Vietnam',
  },
  {
    'prefixes': '681',
    'iso': 'WF',
    'name': 'Wallis & Futuna',
  },
  {
    'prefixes': '212',
    'iso': 'EH',
    'name': 'Western Sahara',
  },
  {
    'prefixes': '967',
    'iso': 'YE',
    'name': 'Yemen',
  },
  {
    'prefixes': '260',
    'iso': 'ZM',
    'name': 'Zambia',
  },
  {
    'prefixes': '263',
    'iso': 'ZW',
    'name': 'Zimbabwe',
  }
]