


let store = null

export default {
  getStore(vuexStore) {
    if (store) {
      return store
    }
    store = vuexStore
  },
  // Checks if in the APP
  isAPP() {
    return this.isWin() && /n8Web/i.test(this.UA())
  },

  // Checks if in Client
  isWin() {
    return typeof window !== 'undefined'
  },

  // Checks if in Mobile
  isMobile() {
    return this.isWin() && /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)
  },

  // Checks if in the Android
  isAndroid() {
    return this.isWin() && /android/i.test(this.UA())
  },

  // Checks if in the Weixin
  isWeixin() {
    return this.isWin() && /MicroMessenger/i.test(this.UA())
  },

  // Checks if in the QQ
  isQQ() {
    return this.isWin() && /QQ\/([\d.]+)/i.test(this.UA())
  },


  // Window userAgent
  UA() {
    return this.isWin() && window.navigator.userAgent
  },

  // Get URL param
  getUrlParam(name) {
    const regex = new RegExp(`(^|&)${name}=([^&]*)(&|$)`)
    const match = window.location.search.substr(1).match(regex)

    if (match != null) { return unescape(decodeURI(match[2])) }
    return null
  },

  // 读取cookies方法
  getCookie(name) {
    let arr = []
    const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
    arr = document.cookie.match(reg)
    if (arr) { return unescape(arr[2]) } return null
  },

  // 写入cookies方法
  setCookie(name, value) {
    const Days = 2
    const exp = new Date()
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
    document.cookie =
      name +
      '=' +
      value +
      ';expires=' +
      exp.toGMTString() +
      ';path=/'
  },

  /**
 * 防抖函数
 * fu 延时调用函数
 * delay 延迟多长时间
 */
  _debounce(fn, delayTime = 500, context) {
    let delay = delayTime || 200
    let timer
    return function (...args) {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(function () {
        timer = null
        fn.apply(context, args)
      }, delay)
    }
  },

  /**
  * 节流函数
  */
  trottle(func, time = 1000, context) {
    let timer = null
    return function (...args) {
      if (!timer) {
        timer = setTimeout(() => {
          clearTimeout(timer)
          timer = null
          func.apply(context, args)
        }, time)
      }
    }
  },

  trottle1(func, time = 1000, context) {
    let previous = new Date(0).getTime()
    return function (...args) {
      let now = new Date().getTime()
      if (now - previous > time) {
        func.apply(context, args)
        previous = now
      }
    }
  },

  // 生成唯一标识
  uuid(len, radix) {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
    let uuid = [],
      i
    radix = radix || chars.length
    if (len) {
      for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix]
    } else {
      let r
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
      uuid[14] = '4'
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | Math.random() * 16
          uuid[i] = chars[i == 19 ? r & 0x3 | 0x8 : r]
        }
      }
    }
    return uuid.join('')
  },

  random_int(begin, end) {
    let num = begin + Math.random() * (end - begin + 1)
    num = Math.floor(num)
    if (num > end) {
      num = end
    }
    return num
  },

  // 时间戳转换
  timeFormat(val, fmt) {
    if (val === 0 || val === '' || val === undefined) return ''
    let ret
    let len = val.toString().length
    let timestamp = val
    if (len === 10) {
      timestamp = Number(timestamp) * 1000
    }
    let date = new Date(timestamp)
    const opt = {
      'Y+': date.getFullYear().toString(),        // 年
      'm+': (date.getMonth() + 1).toString(),     // 月
      'd+': date.getDate().toString(),            // 日
      'H+': date.getHours().toString(),           // 时
      'M+': date.getMinutes().toString(),         // 分
      'S+': date.getSeconds().toString()          // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    }
    for (let k in opt) {
      ret = new RegExp('(' + k + ')').exec(fmt)
      if (ret) {
        fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
      }
    }

    return fmt
  },

  // 删除URL指定参数
  urlDeParams(name) {
    const arr = location.search.substr(1).split('&')
    const obj = {}
    // 获取参数转换为object
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].split('=')
      obj[arr[i][0]] = arr[i][1]
    }
    // 删除指定参数
    delete obj[name]
    return JSON.stringify(obj).replace(/[\"\{\}]/g, '').replace(/\:/g, '=').replace(/\,/g, '&')
  },

  set16ToRgb(str) {
    let reg = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/
    if (!reg.test(str)) { return }
    let newStr = str.toLowerCase().replace(/\#/g, '')
    let len = newStr.length
    if (len === 3) {
      let t = ''
      for (let i = 0; i < len; i++) {
        t += newStr.slice(i, i + 1).concat(newStr.slice(i, i + 1))
      }
      newStr = t
    }
    let arr = [] // 将字符串分隔，两个两个的分隔
    for (let i = 0; i < 6; i = i + 2) {
      let s = newStr.slice(i, i + 2)
      arr.push(parseInt('0x' + s))
    }
    return 'rgb(' + arr.join(',') + ')'
  },

  // 复制字符串
  copy(str) {
    return new Promise((resolve, reject) => {
      let aux = document.createElement('input')
      aux.setAttribute('value', str)
      document.body.appendChild(aux)
      aux.select()
      document.execCommand('copy')
      document.body.removeChild(aux)
      resolve()
    })
  },
  getUrlParams(url) {
    // 通过 ? 分割获取后面的参数字符串
    let urlStr = url.split('?')[1];
    if (!urlStr) return {}
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&');
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=');
      obj[arr[0]] = arr[1];
    }
    return obj;
  }
}