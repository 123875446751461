<template>
  <div v-if="!loading">
    <NavBar
      :content="site.NavBar.props.content"
      v-if="site.NavBar.props.content && !site.NavBar.product"
    />
    <NavBarNew
      :content="site.NavBar.props.content"
      :siteType="siteType"
      :navBarList="navBarList"
      :afterBrand="site.NavBar.afterBrand"
      :beforeBrand="site.NavBar.beforeBrand"
      v-else-if="site.NavBar.props.content && site.NavBar.product"
    />
    <router-view></router-view>
    <FooterBar
      :content="site.FooterBar.props.content"
      v-if="site.NavBar.props.content"
      :siteType="siteType"
      :afterBrand="site.NavBar.afterBrand"
      :hideUnsubscribe="site.FooterBar.hideUnsubscribe"
      :beforeBrand="site.NavBar.beforeBrand"
      :showProduct="site.NavBar.product || false"
      :navBarListFooter="navBarListFooter"
    />
  </div>
</template>

<script>
export default {
  computed: {
    navBarListFooter() {
      if (this.loading) {
        return [];
      }
      const items = JSON.parse(JSON.stringify(this.moreProduct));
      items.list = items.list.map((item) => {
        const isSite = Number(item.type) === Number(this.siteType);
        return {
          title: item.title,
          link: isSite ? window.location.origin : item.url,
          rel: "nofollow",
        };
      });
      return [items];
    },
  },
  data() {
    return {
      loading: true,
      siteType: 1,
      moreProduct: this.$t("moreProduct"),
      navBarList: this.$t("navBarList"),
      site: {
        NavBar: { props: {} },
        FooterBar: { props: {} },
      },
    };
  },
  methods: {
    async init() {
      let siteInfo = sessionStorage.getItem("siteInfo");
      if (siteInfo) {
        this.setData(JSON.parse(siteInfo));
        return;
      }
      let host = this.$extractDomain(window.location.hostname);
      const result = await this.$api.getSiteInfo({ host });
      sessionStorage.setItem("siteInfo", JSON.stringify(result));
      this.setData(result);
    },
    async setData(result) {
      this.siteType = Number(result.data.type);
      const themeJson = require("./theme/default.js");
      const theme =
        themeJson.theme[`${result.data.site.theme || "default"}Theme`];
      if (theme) {
        var root = document.querySelector(":root");
        Object.keys(theme).forEach((key) => {
          root.style.setProperty(key, theme[key]);
        });
      }
      if (
        JSON.stringify(result.data.common) !==
        sessionStorage.getItem("DefaultInfo")
      ) {
        sessionStorage.setItem(
          "DefaultInfo",
          JSON.stringify(result.data.common)
        );
      }
      let localLang = localStorage.getItem("lang") || "";
      let lang = this.$route.query.lang || localLang || "en";
      if (lang !== "en") {
        localStorage.setItem("lang", lang);
      }
      let siteInfo = sessionStorage.getItem("DefaultInfo");
      if (siteInfo) {
        siteInfo = JSON.parse(siteInfo);
        if (!siteInfo.en) {
          this.site = siteInfo;
        } else {
          if (lang === "ph") lang = "en";
          this.site = siteInfo[lang] || siteInfo.en;
        }
      }
      if (this.site.NavBar.isChannel) {
        let channel =
          this.$route.query.channel || localStorage.getItem("channel");
        if (!channel) {
          this.site.NavBar.product = false;
        }
      }
      sessionStorage.setItem("funType", result.data.type);
      let html = document.getElementsByTagName("html")[0];
      let width = window.innerWidth < 980 ? window.innerWidth : 1200;
      /* 640 100  320 50 */
      let fontSize = (width / 750) * 100;
      if (window.innerWidth > 980) {
        html.style.fontSize = 14 + "px";
      } else {
        html.style.fontSize = fontSize + "px";
      }
      this.$nextTick(() => {
        this.loading = false;
      });
      window.innerObj = {
        innerWidth: window.innerWidth,
      };
      window.onresize = function () {
        let html = document.getElementsByTagName("html")[0];
        let width = window.innerWidth < 980 ? window.innerWidth : 980;
        window.innerObj.innerWidth = window.innerWidth;
        /* 640 100  320 50 */
        let fontSize = (width / 750) * 100;
        if (window.innerWidth > 980) {
          html.style.fontSize = 14 + "px";
        } else {
          html.style.fontSize = fontSize + "px";
        }
      };
    },
  },
  async created() {
    this.init();
  },
};
</script>
<style lang="scss">
@import "./scss/index.scss";

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.maks {
  height: 100vh;
}
#accountLink {
  cursor: pointer;
  color: #419eff;
  text-decoration: underline; /* add underline on hover */
}
:root {
  /* 主题背景颜色 */
  --theme-primary: linear-gradient(315deg, #68c1ff 0%, #1681fd 100%);
  /* 默认黑 */
  --theme-primary-black: #000000;
  /* 默认白 */
  --theme-primary-white: #ffffff;
  /* 主题亮色 */
  --theme-primary-suffix: #1681fd;
  --theme-primary-suffix-hover: #0578fd;
  /* 价格 tag */
  --theme-primary-price-tag: #3790f7;
  --theme-primary-price-tag-bg: #e9edf8;
  /* 标签 */
  --theme-label: linear-gradient(115deg, #ff7373 0%, #fd9a5e 100%);
  /* tiny 类 */
  --theme-primary-tiny: #7c7c7c;
  /* footer 背景色 */
  --theme-primary-footer-bg: #1681fd;

  /* 输入框聚焦 */
  --theme-focus: #419eff;
  /* 输入框错误 */
  --theme-focus-err: #ea3042;

  /* banner 容器 */
  // --theme-primary-banner-title: #000000;
  // --theme-primary-banner-des1: #000029;
  /* explain 容器 */
  // --theme-primary-explain-title: #000000;
  // --theme-primary-explain-des: #000000;
  // --theme-primary-explain-des1: #000029;
  /* whyChoose 容器 */
  // --theme-primary-whyChoose-title: #ffffff;
  // --theme-primary-whyChoose-des: #ffffff;
  // --theme-primary-whyChoose-explain: #000000;
  // --theme-primary-whyChoose-exclusive: #ffffff;
  /* price 容器 */
  // --theme-primary-price-title: #000000;
  // --theme-primary-price-title-suffix: #ffffff;
  // --theme-primary-price-des: #000029;
  // --theme-primary-price-explain: #000000;
  // --theme-primary-price-exclusive: #ffffff;
  // --theme-primary-price-tiny: #7c7c7c;
  // --theme-primary-price-tag: #76c225;
  /* question 容器 */
  // --theme-primary-question-title: #000000;
  // --theme-primary-question-des: #000000;
  // --theme-primary-question-tiny: #7c7c7c;
  /* footer 容器 */
  // --theme-primary-footer-title: #ffffff;
  // --theme-primary-footer-des: #ffffff;
  // --theme-primary-footer-a: #ffffff;
  // --theme-primary-footer-tiny: #7c7c7c;
  // --theme-primary-footer-bg: #329678;
  /* 通用类 */
  // --theme-font-normal: #000000;
  // --theme-font-normal-bg: #ffffff;
  // --theme-font-normal-suffix: #ffffff;
}
</style>
