<template>
  <!-- 转换页解释组件 -->
  <div class="explain">
    <h2 class="title">{{ content.title }}</h2>
    <p class="des">{{ content.des }}</p>
    <div class="explain-list">
      <template v-for="(item, idx) in content.list">
        <div :key="idx" class="explain-item">
          <div class="item-left">
            <h3 class="mini-title">{{ item.title }}</h3>
            <p class="des">{{ item.des }}</p>
          </div>
          <img :src="$getFile(item.img)" v-if="item.img" alt="">
          <ImageComparison :dataList="item.dataList" v-if="item.dataList" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Explain',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => { }
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">
@media (min-width: 980px) {
  .explain {
    width: 1180px;
    margin: 0 auto;
    padding: 120px 0;
    text-align: center;
    cursor: default;
    .title {
      margin-bottom: 20px;
      font-size: 36px;
      font-weight: bold;
      font-family: Poppins-Bold, Poppins;
      color: var(--theme-primary-black);
      line-height: 52px;
    }

    .des {
      font-size: 16px;
      font-weight: 400;
      font-family: Poppins-Regular, Poppins;
      color: var(--theme-primary-black);
      line-height: 24px;
    }

    .explain-list {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 80px;
      margin-top: 60px;
      text-align: left;

      .explain-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:nth-child(2n+2) {
          flex-direction: row-reverse;
        }

        .item-left {
          width: 640px;

          .mini-title {
            margin-bottom: 10px;
            font-size: 24px;
            font-weight: bold;
            font-family: Poppins-Bold, Poppins;
            color: var(--theme-primary-black);
            line-height: 32px;
          }
        }

        img {
          width: 480px;
          height: 320px;
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .explain {
    width: 6.86rem;
    margin: 0 auto;
    padding: 1rem 0;
    text-align: center;

    .title {
      margin-bottom: .14rem;
      font-size: .48rem;
      font-family: Poppins, Poppins;
      color: var(--theme-primary-black);
      line-height: .72rem;
    }

    .des {
      font-size: .28rem;
      font-weight: 400;
      font-family: Poppins-Regular, Poppins;
      color: var(--theme-primary-black);
      line-height: .43rem;
    }

    .explain-list {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 0.8rem;
      margin-top: .8rem;
      text-align: left;

      .explain-item {
        display: flex;
        flex-direction: column-reverse;

        .item-left {
          .mini-title {
            margin-bottom: .16rem;
            font-size: .32rem;
            font-family: Poppins-Bold, Poppins;
            color: var(--theme-primary-black);
            line-height: .48rem;
          }
        }

        img {
          display: block;
          width: 6.68rem;
          height: 4.57rem;
          margin: 0 auto .41rem auto;
        }
      }
    }
  }
}
</style>