var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'public-btn',
    _vm.type,
    _vm.customClass,
    { 'primary-load': _vm.loading },
    { [`${_vm.type}-active`]: _vm.disabled },
    { 'is-plain': _vm.plain },
    { [`${_vm.type}-${_vm.disabledClass}`]: !_vm.disabled && _vm.disabledClass }
  ],attrs:{"ontouchstart":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleClick.apply(null, arguments)}}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"load"}),_c('span',[_vm._v(_vm._s(_vm.text))])])
}
var staticRenderFns = []

export { render, staticRenderFns }